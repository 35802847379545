import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@mui/material/Grid";
import ContractCard from "Components/ContractCard";
import { Contract } from "model";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useSearchParams } from "react-router-dom";
import { useContracts } from "services";
import { ButtonBase, createStyles, Theme, withStyles } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import React from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import { Stack } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const CustomDialog = withStyles((theme: Theme) =>
  createStyles({
    open: {},
    container: {
      // maxWidth: 430,
      width: "100%",
      height: "100vh",
      marginInline: "auto",
    },
    paper: {
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("/favicon.png")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: "contain",
      backgroundPositionX: "center",
      backgroundColor: '#fff',
      padding: 0,
      // borderRadius: 20,
      overflow: "visible",
    },
    scrollPaper: {
      alignItems: "center",
      justifyContent: "center",
    },
  })
)(Dialog);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContractListPage = () => {
  const { t } = useTranslation();
  const { contracts = [], isLoading } = useContracts();
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 430px)");
  const phoneNumber = contracts[0]?.contractNo?.slice(0, -2)
  const [searchParams] = useSearchParams();
  const hide_pop_up = searchParams.get('hide_pop_up');

  useEffect(() => {
    if (phoneNumber) {
      fetch(
        `https://api-gateway.prod.rootopia.vn/angels/check-phone-available`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Accept-Language': 'vi',
        },
        body: JSON.stringify({
          phone: phoneNumber,
        })
      },
      ).then((res) => {
        if (res.status === 400 && !hide_pop_up) {
          setOpen(true)
        }
      })
    }
  }, [hide_pop_up, phoneNumber])
  if (isLoading) return <></>;

  return (
    <Box marginBottom={4}>
      <Container>
        <Stack spacing={2}>
          <Typography variant="h1">{t("contract.list.title")}</Typography>
          <Box component="span">
            <Grid
              container
              rowSpacing={{ xs: 2, md: 2 }}
              columnSpacing={{ xs: 2, md: 2 }}
            >
              {contracts.map((contract: Contract) => {
                return (
                  <Grid key={contract.id} item xs={12} sm={6} lg={4}>
                    <ContractCard key={contract.id} contract={contract} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </Container>
      <Outlet />
      <CustomDialog
        fullScreen
        TransitionComponent={Transition}
        open={open}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 15 }}>
          {isMobile ?
            <Stack spacing={3} textAlign={"center"} alignItems={"center"}>
              <Typography variant="h1" width={300}>Từ ngày 11/11/2024, để đảm bảo thông tin thanh toán được cập nhật kịp thời, đầy đủ nhất, quý khách vui lòng sử dụng ứng dụng Rootopia theo các cách sau:</Typography>
              <Stack alignItems="center" spacing={2} direction={"column"} >
                <a href="https://apps.apple.com/vn/app/rootopia-angel/id6451112155?l=vi" target="black">
                  <ButtonBase style={{ borderRadius: '8px', width: 150 }}>
                    <img src="/Apple.png" alt="Install app" style={{ width: "100%" }} />
                  </ButtonBase>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.angelapp" target="black">
                  <ButtonBase style={{ borderRadius: '8px', width: 150 }}>
                    <img src="/Google.png" alt="Install app" style={{ width: "100%" }} />
                  </ButtonBase>
                </a>
              </Stack>
              <Stack alignItems="center" spacing={2}>
                <Typography variant="h1">Hoặc quét mã QR sau bằng điện thoại</Typography>
                <Box width={150}>
                  <img width={'100%'} src="/qr-install-app.jpg" alt="Install app" />
                </Box>
              </Stack>
            </Stack>
            :
            <Stack spacing={3} textAlign={"center"}>
              <Typography variant="h1">Từ ngày 11/11/2024, để đảm bảo thông tin thanh toán được cập nhật kịp thời, đầy đủ nhất, quý khách vui lòng sử dụng ứng dụng Rootopia theo các cách sau: </Typography>
              <Stack alignItems="center" justifyContent="space-evenly" direction={"row"} style={{ marginTop: '130px' }}>
                <a href="https://apps.apple.com/vn/app/rootopia-angel/id6451112155?l=vi" target="black">
                  <ButtonBase style={{ borderRadius: '24px' }}>
                    <img src="/Apple.png" alt="Install app" />
                  </ButtonBase>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.angelapp" target="black">
                  <ButtonBase style={{ borderRadius: '24px' }}>
                    <img src="/Google.png" alt="Install app" />
                  </ButtonBase>
                </a>
              </Stack>
              <Stack alignItems="center" spacing={2}>
                <Typography variant="h1">Hoặc quét mã QR sau bằng điện thoại</Typography>
                <Box width={200}>
                  <img width={'100%'} src="/qr-install-app.jpg" alt="Install app" />
                </Box>
              </Stack>
            </Stack>
          }
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default ContractListPage;
